


















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { BSpinner } from 'bootstrap-vue'
import { cart } from '@/store/cart/cartModule'

@Component({ components: { BSpinner } })
export default class YandexWait extends Vue {
  /**
   * Тэг отмечающий новый продукт или продукт со скидкой
   */
  @Prop({ type: Boolean, default: false })
  readonly isBadge!: false

  /**
   * Флаг для отображения текста о сканировании yandex кода
   */
  get isShowedYandexCodeText(): boolean {
    return cart.isShowedYandexCodeText
  }

  /**
   * Заголовок страницы в зависимости от того это яндекс.бейдж или яндекс.еда
   */
  get title() {
    return this.isBadge ? 'Оплата Яндекс.Badge' : 'Оплата картой Яндекс.Еда'
  }
}
